import type { Symbol } from '../../../serverapi/api';
import { defaultPSDGridLayout } from './grid/PSDGridInitial.config';
import GridModelGraph from '../GridModelGraph';
import PSDGrid from './grid/PSDGrid.class';
import { MxCell } from 'MxGraph';
import { GridLayout } from '@/serverapi/api';
import { PSDModelVertexHandler } from './handlers/PSDModelVertexHadler.class';
import { BPMMxVertexHandler } from '@/mxgraph/handler/BPMMxVertexHandler.class';

class PSDModelGraph extends GridModelGraph {
    grid: PSDGrid;
    createGrid(gridLayout: GridLayout = defaultPSDGridLayout, name) {
        this.grid = new PSDGrid(this, gridLayout, name);
    }

    moveCells(
        cells: MxCell[],
        dx: number,
        dy: number,
        clone: boolean,
        target?: MxCell | null | undefined,
        evt?: Event | undefined,
        mapping?: any,
    ): MxCell[] {
        const result = super.moveCells(cells, dx, dy, clone, target, evt, mapping);
        const [objectCell] = cells.filter(this.isSupportedCell) || [];
        if (objectCell) {
            this.grid.renderEdges(objectCell);
        }

        return result;
    }

    createVertexHandler(state: any): BPMMxVertexHandler {
        return new PSDModelVertexHandler(state);
    }

    getAvailableToReplace(symbol: MxCell, symbols: Symbol[]) {
        const superSymbols = super.getAvailableToReplace(symbol, symbols);

        return this.grid.getAvailableToReplace(symbol, superSymbols);
    }
}

export default PSDModelGraph;
