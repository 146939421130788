import { LocalesService } from '@/services/LocalesService';
import { GridDiagram } from '../../grid/GridDiagram';
import messages from './EPCGrid.messages';
import { showSelectRowNameDialog } from './SideEffects';
import { MxCell } from 'MxGraph';
import { showGridNotification } from '../../grid/SideEffects';
import { NotificationType } from '@/models/notificationType';

class EPCGrid extends GridDiagram {
    lastNewRowTitle = '';
    getNewColumnTitle(): string {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newColumnTitle);
    }

    getNewRowTitle() {
        return this.lastNewRowTitle;
    }

    addRow(cell: MxCell) {
        showSelectRowNameDialog((name) => {
            this.lastNewRowTitle = name;

            super.addRow(cell);
        });
    }

    deleteColumn(cell: MxCell) {
        const index = this.graph.indexOfColumn(cell);
        const firstRow = this.getFirstRowCell(cell);

        if (!firstRow) {
            return;
        }

        try {
            const currentHeadColumn = firstRow.children[index];

            if (!currentHeadColumn.getValue()) {
                showGridNotification(NotificationType.EPC_DELETE_EMPTY_COLUMN_ERROR);

                return;
            }

            super.deleteColumn(cell);
        } catch (e) {
            return;
        }
    }



    getFirstRowCell(tableCell: MxCell): MxCell | null {
        try {
            return tableCell.parent.parent.children[0];
        } catch (e) {
            return null;
        }
    }
}

export default EPCGrid;
