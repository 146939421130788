import type { GridCell, Symbol } from '../../../../serverapi/api';
import { MxCell } from 'MxGraph';
import { createGridCellLayout } from '../../grid/GridCellLayout';
import { GridDiagram } from '../../grid/GridDiagram';
import { LocalesService } from '@/services/LocalesService';
import { groupBy, filter } from 'lodash-es';
import { MessageDescriptor } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from './PSDGrid.messages';
import {
    contentAvailableSymbols,
    firstColumnAvailableSymbols,
    firstRowAvailableSymbols,
    PSDEdgeTypeId,
} from './PSDGridInitial.config';
import { EdgeInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { ShapeStyle, SymbolType } from '@/models/Symbols.constants';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';

class PSDGrid extends GridDiagram {
    isRenderEdges: boolean = true;
    autoEdgeStyleName = 'autoEdge';
    invisibleEdges = true;
    public isGridValidTarget({ target, source, symbolId }) {
        const { type, symbolId: sourceSymbolId } = source?.getValue() || {};
        const actualSymbolId = (symbolId || sourceSymbolId || '').trim();
        const avaialbleSymbolTypes = [
            SymbolType.SHAPE,
            SymbolType.COMMENT,
            SymbolType.LABEL,
            PictureSymbolConstants.PICTURE_SYMBOL_ID,
        ];

        if (avaialbleSymbolTypes.includes(type) || avaialbleSymbolTypes.includes(symbolId)) {
            return true;
        }

        if (Object.values(ShapeStyle).includes(actualSymbolId as ShapeStyle)) {
            return true;
        }

        const { gridRowPosition, gridColumnPosition } = this.getCellGridPosition(target);

        if (!gridRowPosition || !gridColumnPosition) {
            return false;
        }

        if (gridRowPosition === 1 && gridColumnPosition === 1) {
            return false;
        }

        if (gridColumnPosition === 1 && firstColumnAvailableSymbols.includes(actualSymbolId)) {
            return true;
        }

        if (gridRowPosition === 1 && firstRowAvailableSymbols.includes(actualSymbolId)) {
            return true;
        }

        if (gridColumnPosition > 1 && gridRowPosition > 1 && contentAvailableSymbols.includes(actualSymbolId)) {
            return true;
        }

        return false;
    }

    getAvailableToReplace(symbol: MxCell, symbols: Symbol[]) {
        const { symbolId } = symbol?.getValue() || {};

        const filterAvailableSymbols = (id: string[]) => {
            return symbols.filter((symbol) => id.includes(symbol.id));
        };

        if (firstColumnAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(firstColumnAvailableSymbols);
        }

        if (firstRowAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(firstRowAvailableSymbols);
        }

        if (contentAvailableSymbols.includes(symbolId)) {
            return filterAvailableSymbols(contentAvailableSymbols);
        }

        return [];
    }

    private getCellGridPosition(cell: MxCell): {
        gridRowPosition: number | undefined;
        gridColumnPosition: number | undefined;
    } {
        try {
            const gridColumnPosition = this.graph.indexOfColumn(cell);
            const gridRowPosition = this.graph.indexOfRow(cell);

            return { gridRowPosition, gridColumnPosition };
        } catch (e) {
            return { gridRowPosition: undefined, gridColumnPosition: undefined };
        }
    }

    protected addLayout() {
        createGridCellLayout(this.graph);
    }

    public renderEdges(source: MxCell) {
        try {
            if (!this.isRenderEdges) {
                return;
            }

            const { cells, rows, columns } = this.serialize();
            const firstRowId = rows[0]?.id;
            const firstColumnId = columns[0]?.id;

            const model = this.graph.getModel();

            const getPositionByCell = (tableCell: MxCell): GridCell | undefined => {
                const id = tableCell.getId();

                return cells.find((c) => {
                    return c.id === id;
                });
            };

            const modelObjects = filter(model.cells, (cell: MxCell) => {
                return this.isEdgeSupportedCell(cell);
            });

            const filledTableCells = modelObjects.map((c) => {
                return getPositionByCell(c.getParent());
            });

            if (!filledTableCells.length) {
                return;
            }

            const byRowId = groupBy(filledTableCells, 'rowId');
            const byColumnId = groupBy(filledTableCells, 'columnId');

            const [sourceCell] = source?.getStyle().includes('shape=partialRectangle')
                ? (source.children || []).filter(this.isEdgeSupportedCell)
                : this.isEdgeSupportedCell(source)
                ? [source]
                : [];

            if (!sourceCell) {
                return;
            }

            this.clearEdges(sourceCell);

            const targetGridCell: GridCell | undefined = cells.find((c) => {
                return c.id === sourceCell.parent.getId();
            });

            if (!targetGridCell) {
                return;
            }

            type TConnectFn = {
                sourceCell: MxCell;
                localTargetGridCell?: GridCell | undefined;
                reverseDirection?: boolean;
            };

            const connectRow = ({
                sourceCell,
                localTargetGridCell = undefined,
                reverseDirection = false,
            }: TConnectFn) => {
                const firstGridTargetCell =
                    localTargetGridCell ||
                    byRowId[targetGridCell.rowId].find((r) => {
                        return r.columnId === firstColumnId;
                    });

                const firstTargetCell = model.getCell(firstGridTargetCell?.id);

                if (firstTargetCell?.children?.length && sourceCell) {
                    this.connectTableCellChildren({
                        sourceCell,
                        targetGridCell: firstGridTargetCell,
                        title: messages.referTo,
                        edgeTypeId: PSDEdgeTypeId.BELONG,
                        reverseDirection,
                    });
                }
            };

            const connectColumn = ({ sourceCell, localTargetGridCell, reverseDirection = true }: TConnectFn) => {
                const gridTargetCell =
                    localTargetGridCell ||
                    byColumnId[targetGridCell.columnId].find((cell) => {
                        return cell.rowId === firstRowId;
                    });

                const firstTargetCell = model.getCell(gridTargetCell?.id);

                if (firstTargetCell?.children?.length && sourceCell) {
                    this.connectTableCellChildren({
                        sourceCell,
                        targetGridCell: gridTargetCell,
                        title: messages.consistOf,
                        edgeTypeId: PSDEdgeTypeId.CONSIST,
                        reverseDirection,
                    });
                }
            };

            if (targetGridCell?.rowId === firstRowId) {
                const columnGridCells = byColumnId[targetGridCell.columnId];
                columnGridCells.forEach((t) => {
                    if (t.rowId === firstRowId) {
                        return;
                    }

                    connectColumn({ sourceCell, localTargetGridCell: t, reverseDirection: true });
                });

                return;
            }

            if (targetGridCell?.columnId === firstColumnId) {
                const rowGridCells = byRowId[targetGridCell.rowId];
                rowGridCells.forEach((t) => {
                    if (t.columnId === firstColumnId) {
                        return;
                    }

                    connectRow({ sourceCell, localTargetGridCell: t, reverseDirection: true });
                });

                return;
            }

            connectRow({ sourceCell });
            connectColumn({ sourceCell });
        } catch (e) {
            console.error(e);
        } finally {
            this.graph.refresh();
        }

    }

    private clearEdges(source: MxCell) {
        const sourceEdges = (source?.edges || []).filter((edge) => {
            return edge.getStyle().includes(this.autoEdgeStyleName);
        });

        if (sourceEdges.length) {
            this.graph.removeCells(sourceEdges);
        }
    }

    private connectTableCellChildren({
        sourceCell,
        targetGridCell,
        title,
        edgeTypeId,
        reverseDirection = false,
    }: {
        sourceCell: MxCell;
        targetGridCell: GridCell;
        title: MessageDescriptor;
        edgeTypeId: PSDEdgeTypeId;
        reverseDirection?: boolean;
    }) {
        const model = this.graph.getModel();
        const targetTableCells = model.getCell(targetGridCell.id).children.filter(this.isEdgeSupportedCell);
        const parent = this.graph.getDefaultParent();
        const intl = LocalesService.useIntl();
        const invisible = this.invisibleEdges;
        const style = edgeTypeId === PSDEdgeTypeId.CONSIST ? ';horizontal=0;' : '';

        const edges: MxCell[] = [];
        targetTableCells.forEach((targetTableCell) => {
            const [source, target] = reverseDirection ? [targetTableCell, sourceCell] : [sourceCell, targetTableCell];
            const id = uuid();
            const edgeValue = new EdgeInstanceImpl({
                id,
                source: source.id,
                target: target.id,
                name: intl.formatMessage(title),
                invisible,
                style,
                edgeTypeId,
            });

            const edge = this.graph.insertEdge(parent, id, edgeValue, source, target);
            edge.setVisible(!invisible);

            edge.setStyle(`${edge.getStyle()}${style};${this.autoEdgeStyleName}`);
            edges.push(edge);
        });


        return edges;
    }

    private isEdgeSupportedCell(cell: MxCell): boolean {
        return cell.getValue()?.type === 'object';
    }

    getNewColumnTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newColumnConsistOf);
    }

    getNewRowTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newRowReferTo);
    }
}

export default PSDGrid;
